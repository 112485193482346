<template>
  <b-row>
    <b-col cols="auto" class="email-content border p-3">
      Client review request content: {{ locale }}
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
};
</script>
